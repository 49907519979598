<template>
  <div class="wrapper">
    <div class="login-box">
      <el-row :span="8" class="logo-box">
        <img class="logo-img" src="../../../public/img/login/logo2.png" alt="" />
        <div>大数据可视化平台</div>
      </el-row>
      <div class="login-form">
        <el-form
            class="loginForm"
            :model="loginForm"
            :rules="loginRules"
            ref="loginForm"
        >
            <el-form-item prop="username">
            <el-input
                v-model="loginForm.username"
                prefix-icon="el-icon-user"
                placeholder="请输入账号"
                @keyup.enter.native="handleLogin"
                clearable
            ></el-input>
            </el-form-item>

            <el-form-item prop="password">
            <el-input
                v-model="loginForm.password"
                :type="passwordType"
                prefix-icon="el-icon-lock"
                placeholder="请输入密码"
                @keyup.enter.native="handleLogin"
                clearable
            >
                <i
                style="cursor: pointer"
                slot="suffix"
                class="el-icon-view el-input__icon"
                @click="showPassword"
                />
            </el-input>
            </el-form-item>
            <el-form-item prop="code">
            <el-row :span="24">
                <el-col :span="16">
                <el-input v-model="loginForm.code" placeholder="请输入验证码" @keyup.enter.native="handleLogin">
                    <template slot="prefix">
                    <img
                        src="../../../public/img/login/code.png"
                        class="code-img"
                        alt=""
                    />
                    </template>
                </el-input>
                </el-col>
                <el-col :span="8">
                <!-- 图片验证码 -->
                <div class="login-code">
                    <span
                    v-if="code.type === 'text'"
                    class="login-code-img"
                    @click="handleRefreshCode"
                    >{{ code.value }}</span
                    >
                    <img
                    v-else
                    :src="code.src"
                    class="login-code-img"
                    @click="handleRefreshCode"
                    />
                </div>
                </el-col>
            </el-row>
            </el-form-item>
            <el-row>
                <el-col :span="18">
                    <el-checkbox
                        v-model="rememberPwd"
                        class="rememberPwd"
                        style="margin-left: 15px"
                        >记住账号</el-checkbox
                    >
                </el-col>
                <!-- <el-col :span="6">
                <el-button class="forget-pwd-btn" type="text">忘记密码</el-button>
                </el-col> -->
            </el-row>
            <div class="btn-list">
                <el-button type="primary" class="login-btn" @click="handleLogin"
                >登录</el-button
                >
            </div>
        </el-form>
      </div>
    </div>
    <div class="floor">
        <div class="link" style="display: flex;align-items: center;"
          @click="openNewWindows({ ulink: 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35021102001886' })">
          <img src="../../../public/img/login/beian.png" alt="" style="margin-right:6px">
          <div>闽公网安备 35021102001886号</div>
        </div>
        <div
            class="info link"
            @click="openNewWindows({ ulink: 'http://beian.miit.gov.cn/' })"
            >闽ICP备20005737号-1</div
        >
        <div class="info">客服电话：400-678-3198</div>
        <div class="info">技术支持：睿法信息科技（厦门）有限公司</div>
    </div>
  </div>
</template>
<script>
import { randomLenNum } from "@/utils/utils";
import { mapGetters } from "vuex";
import { Base64 } from "js-base64";
import { getStore } from '@/utils/store'

export default {
  data() {
    return {
      loginForm: {
        username: "",
        password: "",
        code: "",
        randomStr: "",
      },
      rememberPwd: true,
      code: {
        src: "",
        value: "",
        len: 4,
        type: "image",
      },
      loginRules: {
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
        pwd: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, message: "密码长度最少为6位", trigger: "blur" },
        ],
        code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 4, max: 4, message: "验证码长度为4位", trigger: "blur" },
        ],
      },
      passwordType: "password",
      tenantId: 1,
    };
  },
  mounted() {
    this.generateCode();
    localStorage.setItem("tenantId", this.tenantId);
  },
  methods: {
    openNewWindows(link) {
      window.open(link.ulink, "_blank");
    },
    showPassword() {
      this.passwordType === ""
        ? (this.passwordType = "password")
        : (this.passwordType = "");
    },
    generateCode() {
      this.loginForm.randomStr = randomLenNum(this.code.len, true);
      let codeUrl = `${window.$website.loginUrl}/code`;
      this.code.type === "text"
        ? (this.code.value = randomLenNum(this.code.len))
        : (this.code.src = `${codeUrl}?randomStr=${this.loginForm.randomStr}`);
    },
    handleRefreshCode() {
      this.generateCode();
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.$store
            .dispatch("LoginByUsername", this.loginForm)
            .then(() => {
              if (this.rememberPwd) {
                localStorage.setItem(
                  "EsUSEN",
                  Base64.encode(this.loginForm.username)
                );
              } else {
                localStorage.removeItem('EsUSEN')
              }
              this.$router.push({ path: "/" });
              localStorage.setItem("loginMethod", "0");
            })
            .catch(() => {
              this.loginForm.code = "";
              this.generateCode();
            });
        }
      });
    },
  },
};
</script>
<style scoped>
.html,
body {
  width: 100%;
  height: 100%;
}
.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background: url("../../../public/img/login/bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.login-box {
  position: absolute;
  width: 480px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.logo-box {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  margin-bottom: 15px;
  color: #fff;
  font-weight: bold;
}
.login-form {
    background: #fff;
    padding:40px 0;
    border-radius: 10px;
}
.logo-img {
  width:60px;
  text-align: center;
  margin-right: 10px;
}
.loginForm {
    width: 90%;
    margin: 10px auto 20px;
    padding: 0 25px;
    box-sizing: border-box;
}
/deep/ .el-input__inner {
  height: 40px;
  line-height: 40px;
  background-color: transparent !important;
  color:#606266 !important;
}
/deep/ .el-input input {
  border: none;
  border-bottom: 1px solid #ebedf2;
}
.code-img {
  width: 12px;
  margin-top: 14px;
  line-height: 40px;
  display: inline;
  margin-left: 7px;
}

.login-btn {
  width: 100%;
  height: 44px;
  font-size: 18px;
  color: #fff;
  background: rgb(0,83,151);
  border-color: rgb(0,83,151);
  border-radius: 5px;
  margin: 0 auto;
  margin-top: 5px;
}
.btn-list {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 25px;
}
.login-code {
  display: flex;
  height: 40px;
  > img {
    width: 100%;
  }
}
/deep/ .el-form-item {
  margin-top: -5px;
}
/deep/ .el-form-item__content{
    padding-right:0
}
.floor {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 70%;
    text-align: center;
    color: rgb(159,159,159);
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    .info {
      margin-left: 16px;
    }
    .link:hover {
      color: #b0a70a;
      cursor: pointer;
    }
}
</style>