<template>
  <div class="index">
    <div class="header">
      <img :src="`${publicPath}img/nav-img.png`"
           alt=""
           width="100%"
           height="140px">
      <div class="content">
        <div class="title">
          <p>
            {{$website.name}}<br />
            <small>{{$website.subName}}</small>
          </p>
        </div>
        <div class="user">
          <div class="avatar-username">
            <div class="avatar">
              <img
                v-if="userInfo.avatar"
                :src="userInfo.avatarUrl"
                alt=""
              />
              <img
                v-else
                :src="`${publicPath}img/login/avatar.png`"
                alt=""
              />
            </div>
            <div class="username">{{ userInfo.username }}</div>
          </div>
          <div class="logout" @click="logout">退出</div>
        </div>
      </div>
      <navs @change="handleChange"></navs>
    </div>
    <el-scrollbar class="main">
      <router-view />
    </el-scrollbar>
  </div>
</template>
<script>
import navs from './nav/index'
import { getUserInfo, fileBaseUrl } from "@/api/login";

export default {
  name: "index",
  components: {
    navs
  },
  data () {
    return {
      publicPath: process.env.VUE_APP_PATH,
      userInfo: {},
      baseUrl: "",
    }
  },
  created() {
    this.baseUrl = fileBaseUrl();
    this.getUserInfo();
  },
  mounted () {
    // this.$notify({
    //   dangerouslyUseHTMLString: true,
    //   title: '可视化数据大屏（演示环境-请勿放生产数据）',
    //   message: `点击购买`,
    //   duration: 0,
    //   onClick: () => {
    //     window.open('https://avuejs.com/views/pay/')
    //   }
    // });
  },
  methods: {
    getUserInfo() {
      // console.log(localStorage.getItem("tenantId"));
      getUserInfo().then((res) => {
        // console.log(res);
        if (res.data.code == 0) {
          let users = res.data.data.sysUser;
          if (users.avatar) {
            let imgUrl = users.avatar.replace("-", "/");
            if (users.avatar.indexOf(this.baseUrl) != -1) {
              users.avatarUrl = imgUrl;
            } else {
              users.avatarUrl = this.baseUrl + imgUrl;
            }
          }
          this.userInfo = users;
          console.log(users,'users');
        }
      });
    },
    logout() {
      this.$confirm("退出系统, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$store.dispatch("LogOut").then(() => {
            this.$router.push({ path: "/login" });
          });
        })
        .catch((err) => {});
    },
    handleChange (item, index) {
      this.$router.push({ path: item.path })
    }
  }
}
</script>
<style lang="scss">
.index {
  height: 100%;
  .header {
    position: relative;
    .nav {
      margin: 0 20px;
      width: 100%;
      position: absolute;
      bottom: 10px;
      border: none;
      .el-menu-item {
        background-color: rgba(0, 0, 0, 0) !important;
      }
    }
    .content {
      position: absolute;
      top: 15px;
      width: 98%;
      height: 60px;
      display: flex;
      .title {
        position: absolute;
        left: 20px;
        font-size: 28px;
        font-style: oblique;
        color: rgb(0, 186, 255);
        font-weight: bold;
        line-height: 35px;
      }
      .title small {
        font-size: 18px;
        color: #9cb4c2;
      }
      .user {
        position: absolute;
        right: 0;
        top:-10px;
        color: #ffffff;
        font-size: 14px;
        height: 100%;
        display: flex;
        align-items: center;
        .avatar-username {
          width: 150px;
          display: flex;
          justify-content: center;
          align-items: center;
          .avatar {
            width: 30px;
            height: 30px;
            border: 1px solid;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 10px;
            > img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .logout {
          width: 100px;
          display: flex;
          justify-content: center;
          cursor: pointer;
        }
      }
    }
  }
  .main {
    width: 100%;
    height: calc(100% - 220px);
  }
}
</style>